import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Building2,
  MapPin,
  Briefcase,
  Euro,
  Calendar,
  ArrowLeft,
  Share2,
  GraduationCap,
  Phone,
  Globe,
  Clock,
  CheckCircle2,
  Gift
} from 'lucide-react';
import { formatSalary, formatDate, formatPostalCode, getWhatsAppLink } from '../lib/utils';
import toast from 'react-hot-toast';
import type { Job } from '../types/job';

export function JobDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [job, setJob] = useState<Job | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB/job/${id}`);
        if (!response.ok) throw new Error('Failed to fetch job');
        const data = await response.json();
        setJob(data);
      } catch (err) {
        setError("Impossible de charger les détails de l'offre.");
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchJob();
  }, [id]);

  const handleShare = async () => {
    try {
      await navigator.share({
        title: job?.job_title,
        text: `Découvrez cette offre d'emploi : ${job?.job_title} chez ${job?.business}`,
        url: window.location.href
      });
    } catch (err) {
      // Fallback to copying to clipboard
      navigator.clipboard.writeText(window.location.href);
      toast.success('Lien copié dans le presse-papier');
    }
  };

  const handleApply = () => {
    if (!job) return;
    
    const jobUrl = window.location.href;
    const whatsappUrl = getWhatsAppLink(job.job_title, jobUrl);
    window.open(whatsappUrl, '_blank');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-teal-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error || !job) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="max-w-5xl mx-auto">
          <div className="bg-red-50 border border-red-200 rounded-xl p-4">
            <p className="text-center text-red-600">{error || "Offre d'emploi non trouvée"}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-teal-600 to-teal-700">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center text-white/80 hover:text-white mb-8 transition-colors"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Retour aux offres
          </button>

          <div className="flex items-start justify-between">
            <div className="flex-1">
              <h1 className="text-3xl font-bold text-white mb-4">
                {job.job_title}
              </h1>
              <div className="flex items-center gap-6 text-teal-50">
                <div className="flex items-center gap-2">
                  <Building2 className="h-5 w-5" />
                  <span>{job.business}</span>
                </div>
                <div className="flex items-center gap-2">
                  <MapPin className="h-5 w-5" />
                  <span>{job.city}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Clock className="h-5 w-5" />
                  <span>{formatDate(job.created_at)}</span>
                </div>
              </div>
            </div>

            <button
              onClick={handleShare}
              className="p-2 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
              title="Partager l'offre"
            >
              <Share2 className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-6">
            {/* Key Information */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Informations clés
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-teal-50 rounded-lg">
                    <Briefcase className="h-5 w-5 text-teal-600" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">Type de contrat</p>
                    <p className="text-sm text-gray-500">{job.contract}</p>
                  </div>
                </div>

                {job.salary > 0 && (
                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-teal-50 rounded-lg">
                      <Euro className="h-5 w-5 text-teal-600" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">Salaire annuel</p>
                      <p className="text-sm text-gray-500">{formatSalary(job.salary)}</p>
                    </div>
                  </div>
                )}

                <div className="flex items-start gap-3">
                  <div className="p-2 bg-teal-50 rounded-lg">
                    <MapPin className="h-5 w-5 text-teal-600" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">Localisation</p>
                    <p className="text-sm text-gray-500">
                      {job.city} ({formatPostalCode(job.postal_code)})
                    </p>
                  </div>
                </div>

                {job.required_experience && (
                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-teal-50 rounded-lg">
                      <GraduationCap className="h-5 w-5 text-teal-600" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">Expérience requise</p>
                      <p className="text-sm text-gray-500">{job.required_experience}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Description */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Description du poste
              </h2>
              <div className="prose prose-teal max-w-none">
                <p className="whitespace-pre-wrap text-gray-600">
                  {job.description}
                </p>
              </div>
            </div>

            {/* Advantages */}
            {job.advantages && job.advantages.length > 0 && (
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                <div className="flex items-center gap-2 mb-4">
                  <Gift className="h-6 w-6 text-teal-600" />
                  <h2 className="text-xl font-semibold text-gray-900">
                    Avantages
                  </h2>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {job.advantages.map((advantage) => (
                    <div key={advantage} className="flex items-center gap-2 text-gray-600">
                      <CheckCircle2 className="h-5 w-5 text-teal-500 flex-shrink-0" />
                      <span>{advantage}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Company Card */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <div className="flex items-center gap-4 mb-6">
                <div className="w-16 h-16 flex items-center justify-center rounded-xl bg-teal-50 text-teal-600 text-2xl font-bold">
                  {job.business.charAt(0)}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {job.business}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {job.business_sector || 'Secteur non précisé'}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                {job.business_phone && (
                  <div className="flex items-center gap-3 text-gray-600">
                    <Phone className="h-5 w-5 text-gray-400" />
                    <span className="text-sm">{job.business_phone}</span>
                  </div>
                )}
                {job.business_website && (
                  <div className="flex items-center gap-3 text-gray-600">
                    <Globe className="h-5 w-5 text-gray-400" />
                    <a
                      href={job.business_website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-teal-600 hover:text-teal-700"
                    >
                      Site web
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/* Apply Button */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <button 
                onClick={handleApply}
                className="w-full bg-teal-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-teal-700 transition-colors"
              >
                Postuler à cette offre
              </button>
              <p className="text-center text-sm text-gray-500 mt-4">
                Cliquez sur le bouton ci-dessus pour postuler via WhatsApp
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}