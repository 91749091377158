// Import useState hook from React
import { useState } from 'react';

// Custom hook to manage job selection state
export function useJobDetails() {
  // State to keep track of the selected job's ID
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);

  // Function to select a job by ID
  const selectJob = (jobId: number) => {
    setSelectedJobId(jobId); // Update the state with the selected job's ID
  };

  // Function to clear the current selection
  const clearSelection = () => {
    setSelectedJobId(null); // Reset the state to null
  };

  // Return the state and functions for external use
  return {
    selectedJobId, // The ID of the currently selected job
    selectJob, // Function to set a selected job
    clearSelection, // Function to reset the selection
  };
}
