import { useEffect, useState } from 'react';
import { Job, JobFilters, JobSearchResult } from '../types/job';
import { fetchJobs } from '../api/jobApi';
import { filterJobs } from '../utils/jobFilters';

export function useJobSearch(
  searchTerm: string,
  filters: JobFilters
): JobSearchResult {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;

  // Fetch jobs
  useEffect(() => {
    async function loadJobs() {
      try {
        setLoading(true);
        const data = await fetchJobs();
        
        // Sort jobs by creation date (newest first)
        const sortedJobs = data.sort((a, b) => {
          const dateA = new Date(a.created_at || 0).getTime();
          const dateB = new Date(b.created_at || 0).getTime();
          return dateB - dateA;
        });
        
        setJobs(sortedJobs);
        setFilteredJobs(sortedJobs);
      } catch (err) {
        console.error('Error fetching jobs:', err);
        setError('Impossible de charger les offres. Veuillez réessayer plus tard.');
      } finally {
        setLoading(false);
      }
    }

    loadJobs();
  }, []);

  // Apply filters
  useEffect(() => {
    const filtered = filterJobs(jobs, searchTerm, filters);
    setFilteredJobs(filtered);
    setPage(1);
  }, [jobs, searchTerm, filters]);

  return {
    jobs,
    filteredJobs,
    loading,
    error,
    page,
    itemsPerPage,
    setPage,
  };
}