import { Department } from '../types/department';

export function getDepartmentFromPostalCode(postalCode: string | number | null | undefined): string | null {
  if (!postalCode) return null;
  
  // Convert to string and ensure it's at least 2 digits
  const postalStr = String(postalCode);
  if (postalStr.length < 2) return null;
  
  return postalStr.substring(0, 2);
}

export function getDepartmentLabel(code: string, departments: Department[]): string {
  const department = departments.find(dept => dept.code === code);
  return department ? `${department.code} - ${department.name}` : code;
}