import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Building2, MapPin, Briefcase, ArrowUpRight } from 'lucide-react';
import { cn, formatDate } from '../../lib/utils';
import { useJobSearch } from '../../hooks/useJobSearch';

export function LatestJobs() {
  const navigate = useNavigate();
  const { jobs, loading, error } = useJobSearch('', {
    departments: [],
    contracts: [],
    state: ''
  });

  // Get the 3 most recent jobs
  const latestJobs = jobs.slice(0, 3);

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {[1, 2, 3].map((i) => (
          <div
            key={i}
            className="animate-pulse bg-white rounded-xl p-6 border border-gray-200"
          >
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-4" />
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-2" />
            <div className="h-4 bg-gray-200 rounded w-1/3" />
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-gray-500">
        Une erreur est survenue lors du chargement des offres.
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {latestJobs.map((job) => (
        <div
          key={job.id}
          onClick={() => navigate(`/jobs/${job.id}`)}
          className={cn(
            "group cursor-pointer",
            "bg-white rounded-xl p-6",
            "border border-gray-200",
            "transition-all duration-300",
            "hover:shadow-xl hover:-translate-y-1"
          )}
        >
          {/* Company Info */}
          <div className="flex items-start gap-4 mb-4">
            <div className="w-12 h-12 flex items-center justify-center rounded-xl bg-teal-50 text-teal-600 text-xl font-bold">
              {job.business.charAt(0)}
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 group-hover:text-teal-600 transition-colors">
                {job.job_title}
              </h3>
              <p className="text-sm text-gray-500">{job.business}</p>
            </div>
          </div>

          {/* Job Details */}
          <div className="space-y-2 mb-4">
            <div className="flex items-center text-gray-600 text-sm">
              <MapPin className="h-4 w-4 text-gray-400 mr-2" />
              {job.city}
            </div>
            <div className="flex items-center text-gray-600 text-sm">
              <Briefcase className="h-4 w-4 text-gray-400 mr-2" />
              {job.contract}
            </div>
          </div>

          {/* View Button */}
          <div className="flex items-center justify-between pt-4 border-t border-gray-100">
            <span className="text-sm text-gray-500">
              {formatDate(job.created_at)}
            </span>
            <span className="text-teal-600 group-hover:text-teal-700 transition-colors">
              <ArrowUpRight className="h-5 w-5" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}