import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Building2, Search, SlidersHorizontal, X, Briefcase, MapPin } from 'lucide-react';
import { JobFilters } from '../components/job/jobFilters';
import { useJobDetails } from '../hooks/useJobDetails';
import { useJobSearch } from '../hooks/useJobSearch';
import { PublicJobCard } from '../components/job/PublicJobCard';

export function Jobs() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [jobFilters, setJobFilters] = useState({
    departments: [] as string[],
    contracts: [] as string[],
    state: '',
  });

  const {
    jobs,
    filteredJobs,
    loading,
    error,
    refreshJobs
  } = useJobSearch(searchTerm, jobFilters);

  const { selectedJobId, selectJob, clearSelection } = useJobDetails();
  const navigate = useNavigate();

  const handleFilterChange = (updatedFilters: typeof jobFilters) => {
    setJobFilters(updatedFilters);
    clearSelection();
  };

  const handleJobSelect = (jobId: number) => {
    selectJob(jobId);
    navigate(`/jobs/${jobId}`);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-teal-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="max-w-7xl mx-auto">
          <div className="bg-red-50 border border-red-200 rounded-xl p-4">
            <p className="text-center text-red-600">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Background Image */}
      <div className="relative bg-gradient-to-br from-teal-600 to-teal-700 pt-16 pb-32 overflow-hidden">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1521737711867-e3b97375f902')] bg-cover bg-center mix-blend-overlay opacity-10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-white mb-4 sm:text-5xl lg:text-6xl">
              Trouvez votre prochain emploi
            </h1>
            <p className="text-xl text-teal-100 mb-12 max-w-2xl mx-auto">
              Des milliers d'opportunités professionnelles vous attendent
            </p>
            
            {/* Enhanced Search Bar */}
            <div className="max-w-2xl mx-auto">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Rechercher par titre, compétence ou lieu..."
                  className="block w-full pl-12 pr-12 py-4 text-lg border border-transparent rounded-xl bg-white/10 text-white placeholder-gray-300 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-white/20 focus:border-transparent shadow-lg"
                />
                {searchTerm && (
                  <button
                    onClick={handleClearSearch}
                    className="absolute inset-y-0 right-0 pr-4 flex items-center"
                  >
                    <X className="h-5 w-5 text-gray-300 hover:text-white transition-colors" />
                  </button>
                )}
              </div>

              {/* Popular Searches */}
              <div className="mt-4 flex items-center justify-center gap-2 flex-wrap">
                {['CDI', 'Temps plein', 'Paris', 'Alternance'].map((term) => (
                  <button
                    key={term}
                    onClick={() => setSearchTerm(term)}
                    className="px-4 py-1.5 bg-white/10 hover:bg-white/20 text-sm text-white rounded-full backdrop-blur-sm transition-colors"
                  >
                    {term}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-24 relative z-10 pb-12">
        {/* Stats and Filters Card */}
        <div className="bg-white rounded-xl shadow-xl p-6 mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-teal-50 rounded-lg">
                <Building2 className="h-6 w-6 text-teal-600" />
              </div>
              <div>
                <h2 className="text-2xl font-semibold text-gray-900">
                  {filteredJobs.length} offre{filteredJobs.length !== 1 ? 's' : ''}
                </h2>
                <p className="text-sm text-gray-500">
                  correspondent à votre recherche
                </p>
              </div>
            </div>
            
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-5 py-2.5 text-sm font-medium text-teal-700 bg-teal-50 rounded-lg hover:bg-teal-100 transition-colors"
            >
              <SlidersHorizontal className="h-4 w-4" />
              Filtrer les résultats
              {(jobFilters.departments.length > 0 || jobFilters.contracts.length > 0) && (
                <span className="ml-1.5 px-2 py-0.5 text-xs bg-teal-600 text-white rounded-full">
                  {jobFilters.departments.length + jobFilters.contracts.length}
                </span>
              )}
            </button>
          </div>

          {/* Active Filters */}
          {(jobFilters.departments.length > 0 || jobFilters.contracts.length > 0) && (
            <div className="flex flex-wrap gap-2 mb-4">
              {jobFilters.departments.map(dept => (
                <span key={dept} className="inline-flex items-center gap-1 px-3 py-1 rounded-full bg-teal-50 text-teal-700 text-sm">
                  <MapPin className="h-3.5 w-3.5" />
                  {dept}
                  <button
                    onClick={() => handleFilterChange({
                      ...jobFilters,
                      departments: jobFilters.departments.filter(d => d !== dept)
                    })}
                    className="ml-1 hover:text-teal-900"
                  >
                    <X className="h-3.5 w-3.5" />
                  </button>
                </span>
              ))}
              {jobFilters.contracts.map(contract => (
                <span key={contract} className="inline-flex items-center gap-1 px-3 py-1 rounded-full bg-teal-50 text-teal-700 text-sm">
                  <Briefcase className="h-3.5 w-3.5" />
                  {contract}
                  <button
                    onClick={() => handleFilterChange({
                      ...jobFilters,
                      contracts: jobFilters.contracts.filter(c => c !== contract)
                    })}
                    className="ml-1 hover:text-teal-900"
                  >
                    <X className="h-3.5 w-3.5" />
                  </button>
                </span>
              ))}
            </div>
          )}

          {/* Filters Panel */}
          {showFilters && (
            <div className="border-t border-gray-100 pt-6">
              <JobFilters
                filters={jobFilters}
                jobs={jobs}
                onFilterChange={handleFilterChange}
              />
            </div>
          )}
        </div>

        {/* Jobs Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {filteredJobs.map(job => (
            <PublicJobCard
              key={job.id}
              job={job}
              onClick={() => handleJobSelect(job.id)}
            />
          ))}

          {filteredJobs.length === 0 && (
            <div className="col-span-full">
              <div className="text-center py-16 bg-white rounded-xl border border-gray-200">
                <div className="p-3 bg-gray-50 rounded-full inline-flex">
                  <Briefcase className="h-8 w-8 text-gray-400" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">
                  Aucune offre trouvée
                </h3>
                <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
                  Essayez de modifier vos critères de recherche pour voir plus de résultats
                </p>
                <button
                  onClick={() => {
                    setSearchTerm('');
                    setJobFilters({
                      departments: [],
                      contracts: [],
                      state: ''
                    });
                  }}
                  className="mt-4 text-sm text-teal-600 hover:text-teal-700 font-medium"
                >
                  Réinitialiser les filtres
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}