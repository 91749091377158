import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

/**
 * Combines and merges class names using clsx and tailwind-merge.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Creates a delay for a specified number of milliseconds.
 */
export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Creates a debounced function that delays invoking the provided function.
 */
export function debounce<T extends (...args: any[]) => any>(
  fn: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), wait);
  };
}

/**
 * Retries a promise-based function with exponential backoff.
 */
export async function retry<T>(
  fn: () => Promise<T>,
  maxAttempts = 3,
  baseDelay = 1000
): Promise<T> {
  let lastError: Error | undefined;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      return await fn();
    } catch (error) {
      lastError = error as Error;
      if (attempt === maxAttempts) break;
      await delay(baseDelay * Math.pow(2, attempt - 1));
    }
  }

  throw lastError;
}

/**
 * Formats a date string into a human-readable format in French locale.
 */
export function formatDate(dateString: string): string {
  try {
    return formatDistanceToNow(new Date(dateString), {
      addSuffix: true,
      locale: fr
    });
  } catch {
    return 'Date inconnue';
  }
}

/**
 * Checks if a given date is in the past.
 */
export function isPastDate(date: Date | string): boolean {
  const compareDate = date instanceof Date ? date : new Date(date);
  return compareDate < new Date();
}

/**
 * Returns a human-readable relative time string.
 */
export function getRelativeTimeString(date: Date | string): string {
  const compareDate = date instanceof Date ? date : new Date(date);
  const formatter = new Intl.RelativeTimeFormat('fr', { numeric: 'auto' });
  const diff = compareDate.getTime() - Date.now();
  const diffDays = Math.round(diff / (1000 * 60 * 60 * 24));
  
  if (Math.abs(diffDays) < 1) {
    const diffHours = Math.round(diff / (1000 * 60 * 60));
    return formatter.format(diffHours, 'hour');
  }
  
  return formatter.format(diffDays, 'day');
}

/**
 * Formats a number into a currency string in euros.
 */
export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  }).format(amount);
}

/**
 * Formats a salary range into a readable string.
 */
export function formatSalaryRange(min: number, max?: number): string {
  const formattedMin = formatCurrency(min);
  if (!max || min === max) return formattedMin;
  const formattedMax = formatCurrency(max);
  return `${formattedMin} - ${formattedMax}`;
}

/**
 * Formats a salary number into a readable string with the EUR currency symbol.
 */
export function formatSalary(salary: number): string {
  return formatCurrency(salary);
}

/**
 * Formats a postal code with its department number.
 */
export function formatPostalCode(code: string | number): string {
  const codeStr = String(code);
  const dept = codeStr.substring(0, 2);
  return `${dept} - ${codeStr}`;
}

/**
 * Formats a decimal number into a percentage string.
 */
export function formatPercent(value: number, decimals = 0): string {
  return `${(value * 100).toFixed(decimals)}%`;
}

/**
 * Generates a random alphanumeric string of the specified length.
 */
export function randomString(length: number): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
}

/**
 * Truncates a string to a specified maximum length.
 */
export function truncate(str: string, maxLength: number, suffix = '...'): string {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength - suffix.length) + suffix;
}

/**
 * Converts a string to title case.
 */
export function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  );
}

/**
 * Validates if a value is defined (not null or undefined).
 */
export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Validates if an email address is in a valid format.
 */
export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

/**
 * Validates if a phone number is in a valid French format.
 */
export function isValidPhoneNumber(phone: string): boolean {
  const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  return phoneRegex.test(phone);
}

// WhatsApp integration
const WHATSAPP_NUMBER = '+33745800515';

export function getWhatsAppLink(jobTitle: string, jobUrl: string): string {
  const message = `Je souhaite postuler à l'offre "${jobTitle}". Voici le lien : ${jobUrl}`;
  return `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${encodeURIComponent(message)}`;
}