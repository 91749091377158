import React from 'react';
import { Job, JobFilters } from '../../../types/job';
import { FilterHeader } from './FilterHeader';
import { DepartmentFilter } from './DepartmentFilter';
import { ContractFilter } from './ContractFilter';
import { ResetButton } from './ResetButton';
import { getDepartmentFromPostalCode } from '../../../utils/departmentUtils';

interface JobFilterComponentsProps {
  filters: JobFilters;
  jobs: Job[];
  onFilterChange: (filters: JobFilters) => void;
}

export function JobFilterComponents({ 
  filters, 
  jobs, 
  onFilterChange 
}: JobFilterComponentsProps) {
  // Get unique departments from jobs
  const departments = Array.from(
    new Set(
      jobs
        .map(job => getDepartmentFromPostalCode(job.postal_code))
        .filter((dept): dept is string => dept !== null)
    )
  ).sort();

  // Get unique contract types from jobs
  const contracts = Array.from(
    new Set(
      jobs
        .map(job => job.contract)
        .filter((contract): contract is string => typeof contract === 'string' && contract.length > 0)
    )
  ).sort();

  const handleDepartmentChange = (dept: string) => {
    const updatedDepartments = filters.departments.includes(dept)
      ? filters.departments.filter(d => d !== dept)
      : [...filters.departments, dept];

    onFilterChange({
      ...filters,
      departments: updatedDepartments,
    });
  };

  const handleContractChange = (contract: string) => {
    const updatedContracts = filters.contracts?.includes(contract)
      ? filters.contracts.filter(c => c !== contract)
      : [...(filters.contracts || []), contract];

    onFilterChange({
      ...filters,
      contracts: updatedContracts,
    });
  };

  const handleReset = () => {
    onFilterChange({
      departments: [],
      contracts: [],
      state: '',
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <FilterHeader />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DepartmentFilter
          departments={departments}
          selectedDepartments={filters.departments}
          onDepartmentChange={handleDepartmentChange}
        />
        <ContractFilter
          contracts={contracts}
          selectedContracts={filters.contracts || []}
          onContractChange={handleContractChange}
        />
        <ResetButton onReset={handleReset} />
      </div>
    </div>
  );
}