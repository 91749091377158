// Import the API base URL from the constants configuration
import { AUTH_API_URL } from '../config/constants';

/**
 * Login function to authenticate a user and fetch their profile.
 * @param email - User's email address.
 * @param password - User's password.
 * @returns An object containing the authentication token and user details.
 */
export async function login(email: string, password: string) {
  try {
    // Step 1: Send login request to the API with email and password.
    const authResponse = await fetch(`${AUTH_API_URL}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });

    // Check if the login response is unsuccessful.
    if (!authResponse.ok) {
      throw new Error('Invalid credentials'); // Throw error if credentials are invalid.
    }

    // Parse the authentication response.
    const authData = await authResponse.json();

    // Ensure the response contains a valid token.
    if (!authData.authToken) {
      throw new Error('Invalid response from auth server'); // Handle unexpected server responses.
    }

    // Step 2: Use the authentication token to fetch the user's profile.
    const meResponse = await fetch(`${AUTH_API_URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${authData.authToken}`, // Include the token in the Authorization header.
      },
    });

    // Check if fetching the profile is unsuccessful.
    if (!meResponse.ok) {
      throw new Error('Failed to fetch user profile'); // Throw error if profile fetch fails.
    }

    // Parse the user's profile data.
    const userData = await meResponse.json();

    // Return both the token and user details as the result.
    return {
      token: authData.authToken,
      user: {
        id: userData.id,
        email: userData.email,
        fullName: userData.name,
        businessId: userData.business_id,
        businessName: userData.business_name,
      },
    };
  } catch (error) {
    // Log any errors that occur during the login process.
    console.error('Login error:', error);
    throw error; // Re-throw the error for the calling function to handle.
  }
}

/**
 * Registers a new business in the system.
 * @param businessData - An object containing the business details.
 * @returns The created business details from the server.
 */
export async function registerBusiness(businessData: {
  company_name: string;
  phone_number: string;
  email: string;
  company_address: string;
  siret_number: string;
  legal_structure: string;
  area: string;
}) {
  try {
    // Send a POST request to create a new business.
    const response = await fetch(`${AUTH_API_URL}/business`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...businessData,
        siret_number: parseInt(businessData.siret_number), // Ensure SIRET number is converted to a number.
      }),
    });

    // Check if the server response indicates an error.
    if (!response.ok) {
      const error = await response.json(); // Parse error details from the server.
      throw new Error(error.message || 'Failed to register business'); // Throw a meaningful error message.
    }

    // Return the created business details.
    return response.json();
  } catch (error) {
    // Log any errors that occur during business registration.
    console.error('Register business error:', error);
    throw error; // Re-throw the error for the calling function to handle.
  }
}

/**
 * Registers a responsible user associated with a business.
 * @param responsibleData - An object containing the responsible user's details.
 * @returns The created responsible user's details from the server.
 */
export async function registerResponsible(responsibleData: {
  business_id: number;
  full_name: string;
  position: string;
  phone_number: string;
  email: string;
  password: string;
}) {
  try {
    // Send a POST request to create a new responsible user.
    const responsibleResponse = await fetch(`${AUTH_API_URL}/auth/signup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...responsibleData,
        name: responsibleData.full_name, // Map "full_name" to "name" as expected by the API.
      }),
    });

    // Check if the server response indicates an error.
    if (!responsibleResponse.ok) {
      const error = await responsibleResponse.json(); // Parse error details from the server.
      throw new Error(error.message || 'Failed to create responsible'); // Throw a meaningful error message.
    }

    // Return the created responsible user's details.
    return responsibleResponse.json();
  } catch (error) {
    // Log any errors that occur during responsible user registration.
    console.error('Register responsible error:', error);
    throw error; // Re-throw the error for the calling function to handle.
  }
}
