export interface Job {
  id: number;
  job_title: string;
  description: string;
  postal_code: string;
  contract: string;
  state: string;
  city: string;
  salary: number;
  business: string;
  business_email: string;
  required_experience?: string;
  business_sector?: string;
  created_at: string;
  active: boolean;
  promoted: boolean;
  business_id: number;
  responsibles_id: number;
  region: string;
  advantages?: string[];
}

export interface JobFilters {
  departments: string[];
  contracts: string[];
  state: string;
}

export interface JobSearchResult {
  jobs: Job[];
  filteredJobs: Job[];
  loading: boolean;
  error: string | null;
  page: number;
  itemsPerPage: number;
  setPage: (page: number) => void;
}

export const JOB_ADVANTAGES = [
  'Tickets restaurant',
  'Mutuelle d\'entreprise',
  'RTT',
  'Formation continue',
  'Prime annuelle',
  'Participation aux bénéfices',
  '13ème mois',
  'Comité d\'entreprise',
  'Salle de sport',
  'Parking gratuit',
  'Transport en commun',
  'Horaires flexibles'
] as const;

export type JobAdvantage = typeof JOB_ADVANTAGES[number];