// Import necessary libraries from Zustand
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// Import the AuthState type for type safety
import { AuthState } from '../types/auth';

// Define the User interface for the user's data
interface User {
  id: number; // User ID
  email: string; // User email
  fullName: string; // User full name
  businessId: number; // Associated business ID
  businessName: string; // Name of the business
}

// Create a Zustand store for authentication
export const useAuth = create<AuthState>()(
  persist(
    (set) => ({
      // Initial state
      token: null, // Authentication token (null when not authenticated)
      user: null, // User data (null when not authenticated)

      // Method to log in a user
      login: (token, user) => set({ token, user }),

      // Method to log out the user
      logout: () => set({ token: null, user: null }),
    }),
    {
      name: 'auth-storage', // Key for local storage persistence
    }
  )
);
