import { StrictMode } from 'react'; // StrictMode highlights potential problems in your application.
import { createRoot } from 'react-dom/client'; // createRoot is the modern API for rendering React components to the DOM.
import App from './App.tsx'; // Import the main App component
import './index.css'; // Import global styles (ensure this file exists)

/**
 * Ensures that the root element exists in the HTML structure.
 * If not, React will throw an error. The `!` tells TypeScript 
 * that `getElementById` won't return null.
 */
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found. Ensure there is a <div id="root"></div> in your index.html.');
}

// Create the root using React 18's createRoot API and render the application
createRoot(rootElement).render(
  <StrictMode>
    <App /> {/* The main application is rendered here */}
  </StrictMode>
);
