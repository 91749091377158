import { useState } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { CreateJobForm } from '../job/CreateJobForm';

interface CreateJobModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function CreateJobModal({ onClose, onSuccess }: CreateJobModalProps) {
  const [error, setError] = useState<string | null>(null);

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
  };

  const handleSuccess = () => {
    onSuccess();
  };

  return (
    <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      {/* Modal Container */}
      <div className="bg-gray-800 rounded-lg max-w-2xl w-full p-6 shadow-xl text-white">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold text-white">
              Créer une nouvelle offre
            </h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors p-1 hover:bg-gray-700 rounded-full"
            aria-label="Fermer"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-900/50 border border-red-500 rounded-lg flex items-start gap-3">
            <AlertCircle className="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5" />
            <div>
              <h3 className="text-sm font-medium text-red-200">
                Une erreur est survenue
              </h3>
              <p className="text-sm text-red-300 mt-1">
                {error}
              </p>
            </div>
          </div>
        )}
        
        {/* Job Creation Form */}
        <CreateJobForm
          onSuccess={handleSuccess}
          onCancel={onClose}
          onError={handleError}
        />
      </div>
    </div>
  );
}