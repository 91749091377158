import React, { useState } from 'react';
import { Building2, Plus } from 'lucide-react';
import { CreateJobModal } from '../components/business/CreateJobModal';
import { useResponsibleJobs } from '../hooks/useResponsibleJobs';
import { useResponsibleData } from '../hooks/useResponsibleData';
import { JobCard } from '../components/job/JobCard';

export function Dashboard() {
  const [showModal, setShowModal] = useState(false);
  const { responsibleData } = useResponsibleData();
  const { jobs, loading, error, refreshJobs, deleteJob } = useResponsibleJobs();

  const handleJobCreated = async () => {
    setShowModal(false);
    try {
      await refreshJobs();
    } catch (error) {
      console.error('Error refreshing jobs:', error);
    }
  };

  const handleDelete = async (id: number) => {
    await deleteJob(id);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-teal-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="max-w-7xl mx-auto">
          <div className="bg-red-50 border border-red-200 rounded-xl p-4">
            <p className="text-center text-red-600">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="p-2 bg-teal-50 rounded-lg">
                <Building2 className="h-6 w-6 text-teal-600" />
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Tableau de bord
                </h1>
                <p className="text-gray-500">
                  {jobs.length} offre{jobs.length !== 1 ? 's' : ''} publiée{jobs.length !== 1 ? 's' : ''}
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowModal(true)}
              className="inline-flex items-center px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors"
            >
              <Plus className="h-5 w-5 mr-2" />
              Nouvelle offre
            </button>
          </div>
        </div>

        {/* Company Info */}
        {responsibleData?.business && (
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8">
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0 w-16 h-16 flex items-center justify-center rounded-xl bg-teal-50 text-teal-600 text-2xl font-bold">
                {responsibleData.business.company_name.charAt(0)}
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {responsibleData.business.company_name}
                </h2>
                <p className="text-gray-500">
                  {responsibleData.business.area}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Jobs Grid */}
        {jobs.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {jobs.map(job => (
              <JobCard
                key={job.id}
                job={job}
                onDelete={handleDelete}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
            <Building2 className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-4 text-lg font-medium text-gray-900">
              Aucune offre publiée
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Commencez par créer votre première offre d'emploi
            </p>
            <button
              onClick={() => setShowModal(true)}
              className="mt-6 inline-flex items-center px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors"
            >
              <Plus className="h-5 w-5 mr-2" />
              Créer une offre
            </button>
          </div>
        )}
      </div>

      {/* Create Job Modal */}
      {showModal && (
        <CreateJobModal
          onClose={() => setShowModal(false)}
          onSuccess={handleJobCreated}
        />
      )}
    </div>
  );
}