// Import necessary React hooks
import { useState, useEffect } from 'react';
import { useAuth } from './useAuth'; // Hook for accessing authentication state

// Define the structure of the Business data
interface Business {
  id: number; // Unique ID of the business
  company_name: string; // Business name
  phone_number: string; // Business phone number
  email: string; // Business email
  company_address: string; // Business address
  siret_number: number; // SIRET number (French unique business identifier)
  legal_structure: string; // Legal structure (e.g., SARL, SAS)
  area: string; // Geographic area
}

// Define the structure of the Responsible Data
interface ResponsibleData {
  id: number; // Unique ID of the responsible
  business_id: number; // Associated business ID
  business: Business | null; // Business details (can be null initially)
  email: string; // Responsible's email
}

/**
 * Custom hook to fetch data related to a responsible user and their associated business.
 */
export function useResponsibleData() {
  const { token, user } = useAuth(); // Get authentication details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [responsibleData, setResponsibleData] = useState<ResponsibleData | null>(null); // Responsible data state

  useEffect(() => {
    // Fetch the responsible data
    const fetchResponsibleData = async () => {
      if (!token || !user?.email) {
        setLoading(false); // Stop loading if authentication is missing
        setError('Authentication required');
        return;
      }

      try {
        setLoading(true); // Start loading
        setError(null); // Reset any previous errors

        // 1. Fetch responsible data
        const responsiblesResponse = await fetch(
          'https://x1lc-aku3-stjs.p7.xano.io/api:O20NksVB/responsibles',
          {
            headers: {
              'Authorization': `Bearer ${token}` // Include authentication token
            }
          }
        );

        if (!responsiblesResponse.ok) {
          throw new Error('Failed to fetch responsible data'); // Handle HTTP errors
        }

        const responsibles = await responsiblesResponse.json();
        const currentResponsible = responsibles.find(
          (r: any) => r.email?.toLowerCase() === user.email?.toLowerCase() // Match the responsible by email
        );

        if (!currentResponsible) {
          throw new Error(`No responsible found for email: ${user.email}`);
        }

        if (!currentResponsible.business_id) {
          throw new Error('No business ID associated with this responsible');
        }

        // 2. Fetch business data using the business ID
        const businessResponse = await fetch(
          `https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB/business/${currentResponsible.business_id}`
        );

        if (!businessResponse.ok) {
          throw new Error('Failed to fetch business data');
        }

        const businessData = await businessResponse.json();

        // Update the responsible data with business details
        setResponsibleData({
          id: currentResponsible.id,
          business_id: currentResponsible.business_id,
          business: businessData,
          email: currentResponsible.email
        });
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Failed to fetch data';
        setError(message); // Set error state
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchResponsibleData(); // Trigger the fetch on component mount or dependency change
  }, [token, user?.email]); // Dependency array ensures re-fetching when token or user changes

  return {
    responsibleData, // The fetched responsible data
    loading, // Whether the data is still loading
    error // Any error message encountered
  };
}
