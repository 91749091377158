// Base URL for authentication-related API endpoints
export const AUTH_API_URL = 'https://x1lc-aku3-stjs.p7.xano.io/api:O20NksVB';

// Base URL for business-related API endpoints
export const BUSINESS_API_URL = 'https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB';

// Object containing different types of job contracts
export const CONTRACT_TYPES = {
  CDI: 'CDI',        // Contrat à Durée Indéterminée (Permanent Contract)
  CDD: 'CDD',        // Contrat à Durée Déterminée (Fixed-Term Contract)
  INTERIM: 'Interim', // Temporary work (Agency Contract)
  STAGE: 'Stage',    // Internship Contract
} as const; // `as const` ensures the values are treated as immutable constants

// Object defining possible states for a job listing
export const JOB_STATES = {
  ACTIVE: 'active',   // Job is currently active and visible
  INACTIVE: 'inactive', // Job is inactive and hidden from listings
  DRAFT: 'draft',     // Job is a draft and not yet published
} as const;

// WhatsApp contact number for inquiries
export const WHATSAPP_NUMBER = '+33745800515';

// Default WhatsApp message template for job-related inquiries
export const WHATSAPP_DEFAULT_MESSAGE = 'Bonjour je souhaite déposer mon CV';
