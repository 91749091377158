import React from 'react';
import { LucideIcon } from 'lucide-react';
import { cn } from '../../lib/utils';

interface FeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

export function FeatureCard({ icon: Icon, title, description }: FeatureCardProps) {
  return (
    <div className={cn(
      "group relative bg-white p-8 rounded-2xl",
      "border border-gray-100",
      "transition-all duration-300",
      "hover:shadow-xl hover:-translate-y-1",
      "overflow-hidden"
    )}>
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-gradient-to-br from-teal-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

      {/* Content */}
      <div className="relative">
        <div className={cn(
          "w-12 h-12 mb-6",
          "flex items-center justify-center",
          "bg-teal-50 text-teal-600",
          "rounded-xl",
          "group-hover:scale-110 transition-transform duration-300"
        )}>
          <Icon className="h-6 w-6" />
        </div>

        <h3 className="text-xl font-semibold text-gray-900 mb-3">
          {title}
        </h3>

        <p className="text-gray-600 leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
}