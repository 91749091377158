import { useState } from 'react';
import toast from 'react-hot-toast';

interface JobPostingData {
  job_title: string;
  description: string;
  business_sector?: string;
  required_experience?: string;
  contract: string;
  salary?: number;
  city: string;
  postal_code: string;
  business: string;
  business_email: string;
  address_t: string;
  state: string;
  responsibles_id: number;
  business_id: number;
  region: string;
}

export function useJobPosting() {
  const [loading, setLoading] = useState(false);

  const createJob = async (jobData: JobPostingData) => {
    try {
      setLoading(true);
      
      const payload = {
        ...jobData,
        responsibles_id: Number(jobData.responsibles_id),
        business_id: Number(jobData.business_id),
        active: true,
        promoted: false,
        created_at: new Date().toISOString(),
        pubdate: new Date().toISOString().split('T')[0],
      };

      console.log('Creating job with payload:', payload);

      const response = await fetch('https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB/job', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || 'Failed to create job');
      }

      const data = await response.json();
      console.log('Job created successfully:', data);
      toast.success('Offre créée avec succès');
      return data;
    } catch (error) {
      console.error('Error creating job:', error);
      toast.error('Erreur lors de la création de l\'offre');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    createJob,
    loading,
  };
}