import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Building2,
  UserCircle,
  CheckCircle,
  Phone,
  Mail,
  MapPin,
  Briefcase,
  Lock,
  ChevronLeft,
  ChevronRight,
  AlertCircle,
  Shield,
  CheckCircle2
} from 'lucide-react';
import { cn } from '../lib/utils';
import toast from 'react-hot-toast';

// Constants remain the same
const STEPS = ['Entreprise', 'Responsable', 'Sécurité', 'Confirmation'] as const;
const LEGAL_STRUCTURES = ['SARL', 'SAS', 'EURL', 'SA', 'SASU', 'Autre'] as const;
const REGIONS_FRANCE = [
  'Auvergne-Rhône-Alpes',
  'Bourgogne-Franche-Comté',
  'Bretagne',
  'Centre-Val de Loire',
  'Corse',
  'Grand Est',
  'Hauts-de-France',
  'Île-de-France',
  'Normandie',
  'Nouvelle-Aquitaine',
  'Occitanie',
  'Pays de la Loire',
  "Provence-Alpes-Côte d'Azur",
  // Régions d'Outre-Mer
  'Guadeloupe',
  'Guyane',
  'La Réunion',
  'Martinique',
  'Mayotte'
] as const;


const POSITIONS = [
  'Directeur',
  'Gérant',
  'Responsable RH',
  'Responsable recrutement',
  'Autre'
] as const;

// Types remain the same
interface BusinessForm {
  company_name: string;
  phone_number: string;
  email: string;
  company_address: string;
  siret_number: string;
  legal_structure: string;
  area: string;
}

interface ResponsibleForm {
  full_name: string;
  position: string;
  phone_number: string;
  email: string;
}

interface SecurityForm {
  password: string;
  confirm_password: string;
  terms_accepted: boolean;
}

// Validation functions remain the same
const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const validatePhone = (phone: string) => {
  const re = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  return re.test(phone);
};

const validateSiret = (siret: string) => {
  return /^\d{14}$/.test(siret);
};

const validatePassword = (password: string) => {
  return password.length >= 8 &&
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /[0-9]/.test(password);
};

export function Register() {
  // State management remains the same
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [businessId, setBusinessId] = useState<number | null>(null);

  const [businessForm, setBusinessForm] = useState<BusinessForm>({
    company_name: '',
    phone_number: '',
    email: '',
    company_address: '',
    siret_number: '',
    legal_structure: '',
    area: '',
  });

  const [responsibleForm, setResponsibleForm] = useState<ResponsibleForm>({
    full_name: '',
    position: '',
    phone_number: '',
    email: '',
  });

  const [securityForm, setSecurityForm] = useState<SecurityForm>({
    password: '',
    confirm_password: '',
    terms_accepted: false,
  });

  // Validation functions remain the same
  const validateBusinessForm = () => {
    if (!businessForm.company_name) return 'Le nom de l\'entreprise est requis';
    if (!validatePhone(businessForm.phone_number)) return 'Numéro de téléphone invalide';
    if (!validateEmail(businessForm.email)) return 'Email invalide';
    if (!businessForm.company_address) return 'L\'adresse est requise';
    if (!validateSiret(businessForm.siret_number)) return 'SIRET invalide';
    if (!businessForm.legal_structure) return 'La structure juridique est requise';
    if (!businessForm.area) return 'La région est requise';
    return null;
  };

  const validateResponsibleForm = () => {
    if (!responsibleForm.full_name) return 'Le nom complet est requis';
    if (!responsibleForm.position) return 'Le poste est requis';
    if (!validatePhone(responsibleForm.phone_number)) return 'Numéro de téléphone invalide';
    if (!validateEmail(responsibleForm.email)) return 'Email invalide';
    return null;
  };

  const validateSecurityForm = () => {
    if (!validatePassword(securityForm.password)) {
      return 'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre';
    }
    if (securityForm.password !== securityForm.confirm_password) {
      return 'Les mots de passe ne correspondent pas';
    }
    if (!securityForm.terms_accepted) {
      return 'Vous devez accepter les conditions d\'utilisation';
    }
    return null;
  };

  // Form submission handler remains the same
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      const businessResponse = await fetch('https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB/business', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...businessForm,
          siret_number: parseInt(businessForm.siret_number),
        }),
      });

      if (!businessResponse.ok) {
        throw new Error('Erreur lors de la création de l\'entreprise');
      }

      const businessData = await businessResponse.json();
      setBusinessId(businessData.id);

      const responsibleResponse = await fetch('https://x1lc-aku3-stjs.p7.xano.io/api:O20NksVB/responsibles', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...responsibleForm,
          business_id: businessData.id,
        }),
      });

      if (!responsibleResponse.ok) {
        throw new Error('Erreur lors de la création du responsable');
      }

      const authResponse = await fetch('https://x1lc-aku3-stjs.p7.xano.io/api:O20NksVB/auth/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: responsibleForm.email,
          password: securityForm.password,
          name: responsibleForm.full_name,
        }),
      });

      if (!authResponse.ok) {
        throw new Error('Erreur lors de la création du compte');
      }

      toast.success('Compte créé avec succès');
      setCurrentStep(STEPS.length - 1);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
      toast.error('Erreur lors de l\'inscription');
    } finally {
      setLoading(false);
    }
  };

  // Navigation handlers remain the same
  const handleNext = () => {
    let validationError = null;

    switch (currentStep) {
      case 0:
        validationError = validateBusinessForm();
        break;
      case 1:
        validationError = validateResponsibleForm();
        break;
      case 2:
        validationError = validateSecurityForm();
        if (!validationError) {
          handleSubmit();
          return;
        }
        break;
    }

    if (validationError) {
      setError(validationError);
      toast.error(validationError);
      return;
    }

    setError(null);
    setCurrentStep((prev) => Math.min(prev + 1, STEPS.length - 1));
  };

  const handleBack = () => {
    setError(null);
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  // Updated UI with cleaner design
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Créez votre espace recruteur
          </h1>
          <p className="text-lg text-gray-600">
            Rejoignez notre plateforme et commencez à recruter les meilleurs talents
          </p>
        </div>

        {/* Step Indicator */}
        <div className="mb-12">
          <div className="max-w-3xl mx-auto">
            <div className="flex items-center justify-between">
              {STEPS.map((step, index) => (
                <React.Fragment key={step}>
                  <div className="flex flex-col items-center">
                    <div
                      className={cn(
                        "w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium transition-all duration-200",
                        currentStep === index
                          ? "bg-teal-600 text-white shadow-lg scale-110"
                          : currentStep > index
                          ? "bg-teal-100 text-teal-600"
                          : "bg-gray-100 text-gray-400"
                      )}
                    >
                      {currentStep > index ? (
                        <CheckCircle className="h-5 w-5" />
                      ) : (
                        index + 1
                      )}
                    </div>
                    <span className={cn(
                      "mt-2 text-sm font-medium",
                      currentStep >= index ? "text-gray-900" : "text-gray-400"
                    )}>
                      {step}
                    </span>
                  </div>
                  {index < STEPS.length - 1 && (
                    <div
                      className={cn(
                        "flex-1 h-0.5 mx-4",
                        currentStep > index
                          ? "bg-teal-600"
                          : "bg-gray-200"
                      )}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-8 max-w-2xl mx-auto">
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <div className="flex items-center gap-3 text-red-700">
                <AlertCircle className="h-5 w-5 flex-shrink-0" />
                <p className="text-sm font-medium">{error}</p>
              </div>
            </div>
          </div>
        )}

        {/* Form Container */}
        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-xl shadow-sm border border-gray-200">
            {/* Form Steps */}
            <div className="p-8">
              {currentStep === 0 && (
                <div className="space-y-6 animate-fadeIn">
                  <div className="flex items-center gap-3 mb-8">
                    <div className="p-2 bg-teal-50 rounded-lg">
                      <Building2 className="h-6 w-6 text-teal-600" />
                    </div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      Information de l'entreprise
                    </h2>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Nom de l'entreprise
                    </label>
                    <input
                      type="text"
                      value={businessForm.company_name}
                      onChange={(e) =>
                        setBusinessForm({ ...businessForm, company_name: e.target.value })
                      }
                      className={cn(
                        "block w-full rounded-lg",
                        "border-gray-300",
                        "focus:ring-2 focus:ring-teal-500 focus:border-teal-500",
                        "transition-all duration-200"
                      )}
                      required
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Téléphone
                      </label>
                      <div className="relative">
                        <Phone className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                        <input
                          type="tel"
                          value={businessForm.phone_number}
                          onChange={(e) =>
                            setBusinessForm({ ...businessForm, phone_number: e.target.value })
                          }
                          className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                          placeholder="01 23 45 67 89"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                      </label>
                      <div className="relative">
                        <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                        <input
                          type="email"
                          value={businessForm.email}
                          onChange={(e) =>
                            setBusinessForm({ ...businessForm, email: e.target.value })
                          }
                          className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Adresse
                    </label>
                    <div className="relative">
                      <MapPin className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                      <input
                        type="text"
                        value={businessForm.company_address}
                        onChange={(e) =>
                          setBusinessForm({ ...businessForm, company_address: e.target.value })
                        }
                        className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Numéro SIRET
                      </label>
                      <input
                        type="text"
                        value={businessForm.siret_number}
                        onChange={(e) =>
                          setBusinessForm({ ...businessForm, siret_number: e.target.value })
                        }
                        className="block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        placeholder="12345678901234"
                        maxLength={14}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Structure juridique
                      </label>
                      <select
                        value={businessForm.legal_structure}
                        onChange={(e) =>
                          setBusinessForm({ ...businessForm, legal_structure: e.target.value })
                        }
                        className="block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        required
                      >
                        <option value="">Sélectionnez</option>
                        {LEGAL_STRUCTURES.map((structure) => (
                          <option key={structure} value={structure}>
                            {structure}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Région
                    </label>
                    <select
                      value={businessForm.area}
                      onChange={(e) =>
                        setBusinessForm({ ...businessForm, area: e.target.value })
                      }
                      className="block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                      required
                    >
                      <option value="">Sélectionnez</option>
                      {REGIONS_FRANCE.map((region) => (
                        <option key={region} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div className="space-y-6 animate-fadeIn">
                  <div className="flex items-center gap-3 mb-8">
                    <div className="p-2 bg-teal-50 rounded-lg">
                      <UserCircle className="h-6 w-6 text-teal-600" />
                    </div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      Information du responsable
                    </h2>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Nom complet
                    </label>
                    <input
                      type="text"
                      value={responsibleForm.full_name}
                      onChange={(e) =>
                        setResponsibleForm({ ...responsibleForm, full_name: e.target.value })
                      }
                      className="block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Poste
                    </label>
                    <div className="relative">
                      <Briefcase className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                      <select
                        value={responsibleForm.position}
                        onChange={(e) =>
                          setResponsibleForm({ ...responsibleForm, position: e.target.value })
                        }
                        className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        required
                      >
                        <option value="">Sélectionnez</option>
                        {POSITIONS.map((position) => (
                          <option key={position} value={position}>
                            {position}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Téléphone
                      </label>
                      <div className="relative">
                        <Phone className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                        <input
                          type="tel"
                          value={responsibleForm.phone_number}
                          onChange={(e) =>
                            setResponsibleForm({ ...responsibleForm, phone_number: e.target.value })
                          }
                          className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                          placeholder="01 23 45 67 89"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                      </label>
                      <div className="relative">
                        <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                        <input
                          type="email"
                          value={responsibleForm.email}
                          onChange={(e) =>
                            setResponsibleForm({ ...responsibleForm, email: e.target.value })
                          }
                          className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className="space-y-6 animate-fadeIn">
                  <div className="flex items-center gap-3 mb-8">
                    <div className="p-2 bg-teal-50 rounded-lg">
                      <Shield className="h-6 w-6 text-teal-600" />
                    </div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      Sécurité
                    </h2>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Mot de passe
                    </label>
                    <div className="relative">
                      <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                      <input
                        type="password"
                        value={securityForm.password}
                        onChange={(e) =>
                          setSecurityForm({ ...securityForm, password: e.target.value })
                        }
                        className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        required
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      8 caractères minimum, avec majuscules, minuscules et chiffres
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Confirmer le mot de passe
                    </label>
                    <div className="relative">
                      <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                      <input
                        type="password"
                        value={securityForm.confirm_password}
                        onChange={(e) =>
                          setSecurityForm({ ...securityForm, confirm_password: e.target.value })
                        }
                        className="pl-10 block w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex items-start pt-4">
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        checked={securityForm.terms_accepted}
                        onChange={(e) =>
                          setSecurityForm({ ...securityForm, terms_accepted: e.target.checked })
                        }
                        className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      />
                    </div>
                    <div className="ml-3">
                      <label className="text-sm font-medium text-gray-700">
                        J'accepte les conditions d'utilisation
                      </label>
                      <p className="mt-1 text-sm text-gray-500">
                        En créant un compte, vous acceptez nos{' '}
                        <a href="#" className="text-teal-600 hover:text-teal-500 underline">
                          conditions d'utilisation
                        </a>{' '}
                        et notre{' '}
                        <a href="#" className="text-teal-600 hover:text-teal-500 underline">
                          politique de confidentialité
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="text-center py-8 animate-fadeIn">
                  <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-teal-100 mb-6">
                    <CheckCircle2 className="h-8 w-8 text-teal-600" />
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    Inscription réussie !
                  </h2>
                  <p className="text-gray-600 mb-8">
                    Votre compte a été créé avec succès. Vous pouvez maintenant vous connecter.
                  </p>
                  <button
                    onClick={() => navigate('/login')}
                    className={cn(
                      "inline-flex justify-center items-center",
                      "px-6 py-3",
                      "bg-teal-600 text-white",
                      "hover:bg-teal-700",
                      "rounded-lg",
                      "text-base font-medium",
                      "shadow-sm",
                      "transition-all duration-200"
                    )}
                  >
                    Se connecter
                  </button>
                </div>
              )}

              {currentStep < 3 && (
                <div className="flex justify-between mt-8 pt-6 border-t border-gray-200">
                  <button
                    type="button"
                    onClick={handleBack}
                    disabled={currentStep === 0}
                    className={cn(
                      "inline-flex items-center px-4 py-2",
                      "rounded-lg",
                      "text-sm font-medium",
                      "transition-all duration-200",
                      "disabled:opacity-50 disabled:cursor-not-allowed",
                      "border border-gray-300",
                      "text-gray-700 hover:bg-gray-50"
                    )}
                  >
                    <ChevronLeft className="h-5 w-5 mr-1" />
                    Retour
                  </button>

                  <button
                    type="button"
                    onClick={handleNext}
                    disabled={loading}
                    className={cn(
                      "inline-flex items-center px-4 py-2",
                      "rounded-lg",
                      "text-sm font-medium",
                      "transition-all duration-200",
                      "disabled:opacity-50 disabled:cursor-not-allowed",
                      "bg-teal-600 text-white",
                      "hover:bg-teal-700",
                      "shadow-sm"
                    )}
                  >
                    {loading ? (
                      <>
                        <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent mr-2" />
                        Chargement...
                      </>
                    ) : (
                      <>
                        {currentStep === 2 ? 'Créer le compte' : 'Suivant'}
                        <ChevronRight className="h-5 w-5 ml-1" />
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}