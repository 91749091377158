import React from 'react';
import { Search, MapPin, ArrowRight } from 'lucide-react';
import { cn } from '../../lib/utils';

interface HeroSectionProps {
  searchTitle: string;
  searchLocation: string;
  onSearchTitleChange: (value: string) => void;
  onSearchLocationChange: (value: string) => void;
  onSearch: () => void;
}

export function HeroSection({
  searchTitle,
  searchLocation,
  onSearchTitleChange,
  onSearchLocationChange,
  onSearch
}: HeroSectionProps) {
  return (
    <div className="relative min-h-[80vh] flex items-center justify-center bg-gradient-to-br from-teal-600 via-teal-700 to-teal-900">
      {/* Animated Background Pattern */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557804506-669a67965ba0')] bg-cover bg-center opacity-10" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-teal-800/50 to-teal-900/80" />
        
        {/* Animated Shapes */}
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="absolute top-10 left-10 w-64 h-64 bg-teal-500/10 rounded-full mix-blend-overlay animate-blob" />
          <div className="absolute top-0 right-0 w-72 h-72 bg-emerald-500/10 rounded-full mix-blend-overlay animate-blob animation-delay-2000" />
          <div className="absolute -bottom-8 left-20 w-72 h-72 bg-teal-500/10 rounded-full mix-blend-overlay animate-blob animation-delay-4000" />
        </div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="text-center">

          <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-8 leading-tight">
            Trouvez votre prochain emploi
            <span className="block bg-gradient-to-r from-teal-200 to-emerald-200 bg-clip-text text-transparent">
              dans le paysagisme
            </span>
          </h1>

          <p className="text-xl text-teal-100 max-w-2xl mx-auto mb-12 leading-relaxed">
            Des milliers d'opportunités professionnelles vous attendent.
            <span className="block">Commencez votre recherche dès maintenant.</span>
          </p>

          {/* Enhanced Search Form */}
          <div className="max-w-4xl mx-auto transform hover:scale-[1.01] transition-all duration-300">
            <div className="bg-white/10 backdrop-blur-md p-3 rounded-2xl shadow-2xl border border-white/20">
              <div className="grid md:grid-cols-[1fr,1fr,auto] gap-3">
                {/* Job Title Search */}
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                    <Search className="h-5 w-5 text-gray-400 group-focus-within:text-teal-500 transition-colors" />
                  </div>
                  <input
                    type="text"
                    value={searchTitle}
                    onChange={(e) => onSearchTitleChange(e.target.value)}
                    placeholder="Quel poste recherchez-vous ?"
                    className={cn(
                      "block w-full pl-12 pr-4 py-4 text-base",
                      "rounded-xl bg-white text-gray-900",
                      "placeholder:text-gray-500",
                      "border-2 border-transparent",
                      "focus:border-teal-500 focus:ring-2 focus:ring-teal-500/20",
                      "transition-all duration-200"
                    )}
                  />
                </div>

                {/* Location Search */}
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                    <MapPin className="h-5 w-5 text-gray-400 group-focus-within:text-teal-500 transition-colors" />
                  </div>
                  <input
                    type="text"
                    value={searchLocation}
                    onChange={(e) => onSearchLocationChange(e.target.value)}
                    placeholder="Où ?"
                    className={cn(
                      "block w-full pl-12 pr-4 py-4 text-base",
                      "rounded-xl bg-white text-gray-900",
                      "placeholder:text-gray-500",
                      "border-2 border-transparent",
                      "focus:border-teal-500 focus:ring-2 focus:ring-teal-500/20",
                      "transition-all duration-200"
                    )}
                  />
                </div>

                {/* Search Button */}
                <button
                  onClick={onSearch}
                  className={cn(
                    "px-8 py-4 text-base font-medium text-white",
                    "bg-gradient-to-r from-teal-500 to-emerald-500",
                    "rounded-xl",
                    "hover:from-teal-600 hover:to-emerald-600",
                    "focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2",
                    "shadow-lg shadow-teal-500/25 hover:shadow-xl hover:shadow-teal-500/30",
                    "transition-all duration-200",
                    "flex items-center justify-center gap-2"
                  )}
                >
                  <span>Rechercher</span>
                  <ArrowRight className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Popular Searches */}
            <div className="mt-8 flex flex-wrap justify-center gap-3">
              {['CDI', 'Jardinier', 'Paysagiste', 'Paris', 'Lyon', 'Marseille'].map((term) => (
                <button
                  key={term}
                  onClick={() => onSearchTitleChange(term)}
                  className={cn(
                    "px-4 py-2 text-sm font-medium text-teal-100",
                    "bg-white/10 hover:bg-white/20",
                    "rounded-full backdrop-blur-sm",
                    "transition-all duration-200",
                    "border border-white/20",
                    "hover:border-white/40",
                    "hover:scale-105",
                    "focus:outline-none focus:ring-2 focus:ring-white/20"
                  )}
                >
                  {term}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}