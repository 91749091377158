import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, Briefcase, Users, ArrowRight } from 'lucide-react';
import { HeroSection } from '../components/home/HeroSection';
import { FeatureCard } from '../components/home/FeatureCard';
import { StepCard } from '../components/home/StepCard';
import { LatestJobs } from '../components/home/LatestJobs';
import { getWhatsAppLink, cn } from '../lib/utils';

export function Index() {
  const [searchTitle, setSearchTitle] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate('/jobs', {
      state: { title: searchTitle, location: searchLocation },
    });
  };

  const features = [
    {
      icon: MessageCircle,
      title: 'Assistant WhatsApp Intelligent',
      description:
        "Notre bot WhatsApp vous guide 24/7 dans votre recherche d'emploi avec des réponses personnalisées.",
    },
    {
      icon: Briefcase,
      title: 'Offres Personnalisées',
      description:
        'Recevez des offres d\'emploi sur mesure selon votre profil et vos préférences professionnelles.',
    },
    {
      icon: Users,
      title: 'Profil Transmis au Recruteur',
      description:
        'Vos informations ainsi que votre CV seront transmises au recruteur pour une meilleure visibilité.',
    },
  ];

  const steps = [
    {
      number: 1,
      title: 'Créez votre profil',
      description:
        'Inscrivez-vous simplement sur WhatsApp et créez votre profil en quelques minutes.',
      link: getWhatsAppLink(),
      isExternal: true,
    },
    {
      number: 2,
      title: 'Découvrez les offres',
      description:
        'Explorez des offres personnalisées correspondant à vos critères de recherche.',
      link: '/jobs',
    },
    {
      number: 3,
      title: 'Postulez rapidement',
      description:
        'Envoyez votre candidature en un clic et suivez son évolution en temps réel.',
      link: '/jobs',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <HeroSection
        searchTitle={searchTitle}
        searchLocation={searchLocation}
        onSearchTitleChange={setSearchTitle}
        onSearchLocationChange={setSearchLocation}
        onSearch={handleSearch}
      />

      {/* Features Section */}
      <section className="py-24 bg-gradient-to-b from-teal-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Pourquoi nous choisir ?
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Des outils innovants pour simplifier votre recherche d'emploi
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Comment ça marche ?
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Trouvez votre prochain emploi en trois étapes simples
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step) => (
              <StepCard key={step.number} {...step} />
            ))}
          </div>
        </div>
      </section>

      {/* Latest Jobs Section */}
      <section className="py-24 bg-gradient-to-b from-white to-teal-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Dernières Offres
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Découvrez les opportunités les plus récentes
            </p>
          </div>
          <LatestJobs />
          <div className="text-center mt-12">
            <button
              onClick={() => navigate('/jobs')}
              className={cn(
                "inline-flex items-center",
                "px-8 py-4",
                "bg-teal-600 hover:bg-teal-700",
                "text-white font-medium",
                "rounded-xl",
                "transition-colors duration-200",
                "shadow-lg hover:shadow-xl"
              )}
            >
              Voir toutes les offres
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-to-br from-teal-600 to-teal-800 relative overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557804506-669a67965ba0')] bg-cover bg-center opacity-10" />
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
            Prêt à commencer votre recherche ?
          </h2>
          <p className="text-xl text-teal-100 max-w-2xl mx-auto mb-12">
            Rejoignez notre plateforme et accédez à des milliers d'offres
            d'emploi dans le secteur du paysagisme.
          </p>
          <button
            onClick={() => window.open(getWhatsAppLink(), '_blank')}
            className={cn(
              "inline-flex items-center",
              "px-8 py-4",
              "bg-white hover:bg-teal-50",
              "text-teal-600 font-medium",
              "rounded-xl",
              "transition-colors duration-200",
              "shadow-lg hover:shadow-xl"
            )}
          >
            <MessageCircle className="mr-2 h-5 w-5" />
            Commencer sur WhatsApp
          </button>
        </div>
      </section>
    </div>
  );
}