import React, { useState } from 'react';
import { ChevronDown, X } from 'lucide-react';
import { ContractFilterProps } from '../../../types/contract';

/**
 * A filter component for selecting contract types.
 * Allows users to select/deselect contract types from a dropdown menu and displays the selected options.
 */
export function ContractFilter({ 
  contracts = [], // List of available contract types
  selectedContracts = [], // List of currently selected contract types
  onContractChange // Callback function for handling contract selection changes
}: ContractFilterProps) {
  const [isOpen, setIsOpen] = useState(false); // State to control dropdown visibility

  return (
    <div className="relative">
      {/* Title */}
      <h3 className="text-lg font-semibold mb-3">Types de contrat</h3>
      
      {/* Display selected contract tags */}
      <div className="flex flex-wrap gap-2 mb-2">
        {selectedContracts.map(contract => (
          <span 
            key={contract} 
            className="inline-flex items-center px-2 py-1 rounded-md bg-blue-100 text-blue-800 text-sm"
          >
            {contract} {/* Display the name of the selected contract */}
            <button
              onClick={() => onContractChange(contract)} // Remove the contract from the selection
              className="ml-1 hover:text-blue-600"
            >
              <X size={14} /> {/* Icon to remove the selected contract */}
            </button>
          </span>
        ))}
      </div>

      {/* Dropdown toggle button */}
      <button
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown visibility
        className="w-full px-4 py-2 text-left border rounded-md bg-white flex items-center justify-between hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span className="text-gray-700">
          {selectedContracts.length 
            ? `${selectedContracts.length} contrat${selectedContracts.length > 1 ? 's' : ''} sélectionné${selectedContracts.length > 1 ? 's' : ''}` // Pluralization based on selected count
            : 'Sélectionner des contrats'} {/* Default prompt if no contracts are selected */}
        </span>
        <ChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} size={20} /> {/* Dropdown arrow icon */}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
          {/* Scrollable list of contracts */}
          <div className="max-h-60 overflow-y-auto">
            {contracts.map(contract => (
              <label
                key={contract} // Unique key for each contract
                className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
              >
                <input
                  type="checkbox"
                  checked={selectedContracts.includes(contract)} // Check if the contract is already selected
                  onChange={() => {
                    onContractChange(contract); // Add/remove the contract to/from the selection
                    setIsOpen(false); // Close the dropdown after selection
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm">{contract}</span> {/* Display the contract name */}
              </label>
            ))}

            {/* Message if no contracts are available */}
            {contracts.length === 0 && (
              <div className="px-4 py-2 text-sm text-gray-500">
                Aucun contrat disponible
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
