import React from 'react';
import { Building2, MapPin, Briefcase, Euro, Calendar, ArrowUpRight, GraduationCap, Clock } from 'lucide-react';
import type { Job } from '../../types/job';

interface PublicJobCardProps {
  job: Job;
  onClick?: () => void;
}

export function PublicJobCard({ job, onClick }: PublicJobCardProps) {
  const formatDate = (date: string) => {
    const now = new Date();
    const postDate = new Date(date);
    const diffTime = Math.abs(now.getTime() - postDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) return 'Publié hier';
    if (diffDays < 7) return `Publié il y a ${diffDays} jours`;
    
    return postDate.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long'
    });
  };

  const formatSalary = (salary: number) => {
    if (!salary) return 'Salaire non précisé';
    
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(salary);
  };

  return (
    <article 
      onClick={onClick}
      className="group relative bg-white hover:bg-gray-50 rounded-xl border border-gray-200 transition-all duration-200 cursor-pointer"
    >
      {/* New Tag if job is less than 2 days old */}
      {job.created_at && new Date(job.created_at).getTime() > Date.now() - 2 * 24 * 60 * 60 * 1000 && (
        <div className="absolute -top-2 -right-2 px-2 py-1 bg-green-500 text-white text-xs font-medium rounded-full shadow-sm">
          Nouveau
        </div>
      )}

      <div className="p-6">
        {/* Header */}
        <div className="flex items-start gap-4 mb-4">
          {/* Company Logo */}
          <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-lg bg-gray-100 text-gray-600">
            {job.business?.charAt(0) || <Building2 className="h-6 w-6" />}
          </div>

          {/* Title and Company */}
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2 mb-1">
              <h2 className="text-lg font-semibold text-gray-900 truncate group-hover:text-blue-600 transition-colors">
                {job.job_title}
              </h2>
              {/* Contract Type Badge */}
              <span className={`
                inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                ${job.contract === 'CDI' ? 'bg-blue-50 text-blue-700' :
                  job.contract === 'CDD' ? 'bg-purple-50 text-purple-700' :
                  job.contract === 'Stage' ? 'bg-green-50 text-green-700' :
                  job.contract === 'Alternance' ? 'bg-orange-50 text-orange-700' :
                  'bg-gray-50 text-gray-700'}
              `}>
                {job.contract}
              </span>
            </div>
            <h3 className="text-sm font-medium text-gray-600 truncate">
              {job.business}
            </h3>
          </div>
        </div>

        {/* Description */}
        <p className="text-sm text-gray-600 mb-4 line-clamp-2">
          {job.description}
        </p>

        {/* Details Grid */}
        <div className="grid grid-cols-2 gap-3 text-sm">
          <div className="flex items-center text-gray-500">
            <MapPin className="h-4 w-4 text-gray-400 mr-1.5" />
            <span className="truncate">{job.city || 'Non précisé'}</span>
          </div>

          {job.salary > 0 && (
            <div className="flex items-center text-gray-500">
              <Euro className="h-4 w-4 text-gray-400 mr-1.5" />
              <span>{formatSalary(job.salary)}/an</span>
            </div>
          )}

          {job.required_experience && (
            <div className="flex items-center text-gray-500">
              <GraduationCap className="h-4 w-4 text-gray-400 mr-1.5" />
              <span className="truncate">{job.required_experience}</span>
            </div>
          )}

          {job.created_at && (
            <div className="flex items-center text-gray-500">
              <Clock className="h-4 w-4 text-gray-400 mr-1.5" />
              <span>{formatDate(job.created_at)}</span>
            </div>
          )}
        </div>

        {/* Apply Button - Only visible on hover */}
        <div className="mt-4 pt-4 border-t border-gray-100">
          <button className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 transition-colors">
            Voir l'offre
            <ArrowUpRight className="ml-2 -mr-1 h-4 w-4" />
          </button>
        </div>
      </div>
    </article>
  );
}