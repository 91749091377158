import { Link } from 'react-router-dom';
import { Menu, X, User, LogOut } from 'lucide-react';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { cn, getWhatsAppLink } from '../lib/utils';
import logo from '../assets/logo.png'; // Importez le logo ici

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, user, logout } = useAuth();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Adjust navbar height */}
        <div className="flex justify-between items-center h-40"> {/* Hauteur massive */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              {/* Augmented logo size */}
              <img
                src={logo}
                alt="Emploi Paysagiste Logo"
                className="h-36 w-auto" // Taille encore augmentée pour le logo
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex sm:items-center sm:space-x-6">
  <Link
    to="/"
    className="text-text-primary hover:text-primary-main px-3 py-2 rounded-md text-base font-medium"
  >
    Accueil
  </Link>
  <Link
    to="/jobs"
    className="text-text-primary hover:text-primary-main px-3 py-2 rounded-md text-base font-medium"
  >
    Offres d'emploi
  </Link>
  <a
    href={getWhatsAppLink()}
    className="block px-5 py-2 bg-primary-main text-white rounded-md hover:bg-primary-dark text-base font-medium"
    target="_blank"
    rel="noopener noreferrer"
  >
    Déposer mon CV
  </a>
  {isAuthenticated ? (
    <>
      <Link
        to="/dashboard"
        className="text-text-primary hover:text-primary-main px-3 py-2 rounded-md text-base font-medium"
      >
        Tableau de bord
      </Link>
      <div className="flex items-center space-x-4">
        <span className="text-sm text-text-secondary">{user?.fullName}</span>
        <button
          onClick={logout}
          className="text-text-primary hover:text-primary-main"
          aria-label="Se déconnecter"
        >
          <LogOut className="h-6 w-6" />
        </button>
      </div>
    </>
  ) : (
    <Link
      to="/login"
      className="flex items-center text-text-primary hover:text-primary-main px-3 py-2 rounded-md text-base font-medium"
    >
      <User className="h-6 w-6 mr-2" />
      Connexion
    </Link>
  )}
</div>


          {/* Mobile menu button */}
          <div className="sm:hidden flex items-center">
  <button
    onClick={toggleMenu}
    className="text-text-primary hover:text-primary-main"
    aria-label="Toggle menu"
  >
    {isMenuOpen ? (
      <X className="h-8 w-8" />
    ) : (
      <Menu className="h-8 w-8" />
    )}
  </button>
</div>

        </div>
      </div>

      {/* Mobile Navigation */}
      <div
        className={cn(
          'sm:hidden',
          isMenuOpen ? 'block' : 'hidden'
        )}
      >
        <div className="px-2 pt-3 pb-4 space-y-3">
  <Link
    to="/"
    className="block px-4 py-2 rounded-md text-base font-medium text-text-primary hover:text-primary-main"
  >
    Accueil
  </Link>
  <Link
    to="/jobs"
    className="block px-4 py-2 rounded-md text-base font-medium text-text-primary hover:text-primary-main"
  >
    Offres d'emploi
  </Link>
  <a
    href={getWhatsAppLink()}
    className="block px-4 py-2 bg-primary-main text-white rounded-md hover:bg-primary-dark text-base font-medium text-center"
    target="_blank"
    rel="noopener noreferrer"
  >
    Déposer mon CV
  </a>
  {isAuthenticated ? (
    <>
      <Link
        to="/dashboard"
        className="block px-4 py-2 rounded-md text-base font-medium text-text-primary hover:text-primary-main"
      >
        Tableau de bord
      </Link>
      <div className="px-4 py-2 flex items-center justify-between">
        <span className="text-sm text-text-secondary">{user?.fullName}</span>
        <button
          onClick={logout}
          className="text-text-primary hover:text-primary-main"
          aria-label="Se déconnecter"
        >
          <LogOut className="h-6 w-6" />
        </button>
      </div>
    </>
  ) : (
    <Link
      to="/login"
      className="flex items-center px-4 py-2 rounded-md text-base font-medium text-text-primary hover:text-primary-main"
    >
      <User className="h-6 w-6 mr-2" />
      Connexion
    </Link>
  )}
</div>

      </div>
    </nav>
  );
}
