/**
 * A button component for resetting filters.
 * 
 * @param {{ onReset: () => void }} props The props of the component. 
 * @prop {() => void} onReset The function to be called when the button is clicked.
 * 
 * @returns {JSX.Element} The button component.
 */
export function ResetButton({ onReset }: {
  onReset: () => void;
}): JSX.Element {
  return (
    <div className="flex items-end">
      <button
        onClick={onReset}
        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        Réinitialiser les filtres
      </button>
    </div>
  );
}
