// Import necessary dependencies and hooks
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../store/auth'; // Custom authentication hook
import { login as loginApi } from '../api/auth'; // API function for login

// Main LoginPage component
export function Login() {
  // State variables to manage form inputs, errors, and loading status
  const [email, setEmail] = useState(''); // User email
  const [password, setPassword] = useState(''); // User password
  const [error, setError] = useState(''); // Error message to display
  const [loading, setLoading] = useState(false); // Loading state for the form

  const navigate = useNavigate(); // Hook for programmatic navigation
  const { login } = useAuth(); // Access to the login function from authentication store

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form behavior (page reload)
    setError(''); // Clear any existing error
    setLoading(true); // Set loading state to true

    try {
      // Call the login API with email and password
      const { token, user } = await loginApi(email, password);

      // If successful, store the token and user info using the auth hook
      login(token, user);

      // Navigate to the dashboard after login
      navigate('/dashboard');
    } catch (err) {
      // Set an error message if the API call fails
      setError(err.message);
    } finally {
      // Reset the loading state
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-light flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {/* Header Section */}
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-dark">
          Connexion Entreprise
        </h2>
      </div>

      {/* Login Form Section */}
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-background-main py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {/* Form */}
          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Error Display */}
            {error && (
              <div className="bg-accent-error-light border border-accent-error text-red-800 px-4 py-3 rounded">
                {error}
              </div>
            )}

            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-primary-dark">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)} // Update email state
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-light focus:border-primary-light"
                />
              </div>
            </div>

            {/* Password Input */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-primary-dark">
                Mot de passe
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)} // Update password state
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-light focus:border-primary-light"
                />
              </div>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                disabled={loading} // Disable button while loading
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-main hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light disabled:opacity-50"
              >
                {loading ? 'Connexion...' : 'Se connecter'} {/* Show loading text */}
              </button>
            </div>
          </form>

          {/* Footer Section */}
          <div className="mt-6">
            {/* Separator */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-background-main text-text-secondary">
                  Pas encore de compte ?
                </span>
              </div>
            </div>

            {/* Register Link */}
            <div className="mt-6">
              <Link
                to="/register"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-main bg-background-main hover:bg-background-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light"
              >
                Créer un compte
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
