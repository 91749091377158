import { Trash2, Building2, MapPin, Briefcase, Euro, Calendar } from 'lucide-react';
import type { Job } from '../../types/job';

interface JobCardProps {
  job: Job;
  onDelete?: (id: number) => void;
}

export function JobCard({ job, onDelete }: JobCardProps) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatSalary = (salary: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(salary);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200 hover:border-teal-500 transition-all duration-300 group">
      <div className="p-6">
        {/* Header */}
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-teal-50 rounded-lg">
            <Building2 className="h-5 w-5 text-teal-600" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-900 group-hover:text-teal-600 transition-colors">
              {job.job_title}
            </h3>
            <p className="text-sm text-gray-500">{job.business}</p>
          </div>
        </div>

        {/* Description */}
        <p className="text-gray-600 mb-6 line-clamp-3">
          {job.description}
        </p>

        {/* Details Grid */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="flex items-center text-gray-600">
            <div className="p-1.5 bg-gray-50 rounded-lg mr-2">
              <MapPin className="h-4 w-4 text-teal-600" />
            </div>
            <div className="flex flex-col">
              <span className="text-sm">{job.city}</span>
              <span className="text-xs text-gray-500">{job.postal_code}</span>
            </div>
          </div>

          <div className="flex items-center text-gray-600">
            <div className="p-1.5 bg-gray-50 rounded-lg mr-2">
              <Briefcase className="h-4 w-4 text-teal-600" />
            </div>
            <div className="flex flex-col">
              <span className="text-sm">{job.contract}</span>
              {job.required_experience && (
                <span className="text-xs text-gray-500">
                  {job.required_experience}
                </span>
              )}
            </div>
          </div>

          {job.salary > 0 && (
            <div className="flex items-center text-gray-600">
              <div className="p-1.5 bg-gray-50 rounded-lg mr-2">
                <Euro className="h-4 w-4 text-teal-600" />
              </div>
              <div className="flex flex-col">
                <span className="text-sm">{formatSalary(job.salary)}</span>
                <span className="text-xs text-gray-500">par an</span>
              </div>
            </div>
          )}

          {job.created_at && (
            <div className="flex items-center text-gray-600">
              <div className="p-1.5 bg-gray-50 rounded-lg mr-2">
                <Calendar className="h-4 w-4 text-teal-600" />
              </div>
              <div className="flex flex-col">
                <span className="text-sm">Publié le</span>
                <span className="text-xs text-gray-500">
                  {formatDate(job.created_at)}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* Footer with Delete Button */}
        <div className="pt-4 border-t border-gray-100">
          <button
            onClick={() => onDelete?.(job.id)}
            className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-teal-600 hover:bg-teal-50 rounded-lg transition-colors"
            title="Supprimer l'offre"
          >
            <Trash2 className="h-4 w-4" />
            <span>Supprimer l'offre</span>
          </button>
        </div>
      </div>
    </div>
  );
}