import { useState } from 'react';
import { useJobPosting } from '../../hooks/useJobPosting';
import { useResponsibleData } from '../../hooks/useResponsibleData';
import { Briefcase, MapPin, Euro, FileText, ChevronLeft, ChevronRight, Building2, Gift } from 'lucide-react';
import { JOB_ADVANTAGES } from '../../types/job';
import toast from 'react-hot-toast';

interface CreateJobFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  onError: (error: string) => void;
}

export function CreateJobForm({ onSuccess, onCancel, onError }: CreateJobFormProps) {
  const { createJob, loading: createLoading } = useJobPosting();
  const { responsibleData, loading: dataLoading, error } = useResponsibleData();

  const [formData, setFormData] = useState({
    job_title: '',
    description: '',
    required_experience: '',
    contract: 'CDI',
    salary: 0,
    city: '',
    postal_code: '',
    advantages: [] as string[]
  });

  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (dataLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error || !responsibleData?.business) {
    const errorMessage = error || 'Unable to load business data';
    onError(errorMessage);
    return null;
  }

  const { business } = responsibleData;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isSubmitting) return;

    // Validate required fields
    if (!formData.job_title.trim()) {
      toast.error('Le titre du poste est requis');
      setStep(1);
      return;
    }

    if (!formData.description.trim()) {
      toast.error('La description du poste est requise');
      setStep(3);
      return;
    }

    if (!formData.postal_code) {
      toast.error('Le code postal est requis');
      setStep(2);
      return;
    }

    try {
      setIsSubmitting(true);

      if (!responsibleData.id) {
        throw new Error('No responsible ID available');
      }

      const jobData = {
        job_title: formData.job_title,
        description: formData.description,
        required_experience: formData.required_experience,
        contract: formData.contract,
        salary: formData.salary,
        city: formData.city,
        postal_code: formData.postal_code,
        business: business.company_name,
        business_email: business.email,
        address_t: business.company_address,
        state: business.area,
        responsibles_id: responsibleData.id,
        active: true,
        promoted: false,
        business_id: business.id,
        region: business.area,
        advantages: formData.advantages.length > 0 ? formData.advantages : undefined
      };

      const createdJob = await createJob(jobData);
      
      if (!createdJob) {
        throw new Error('Failed to create job');
      }

      toast.success('Offre créée avec succès');
      onSuccess();
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Erreur lors de la création de l\'offre';
      onError(message);
      console.error('Error in handleSubmit:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'salary' || name === 'postal_code' ? Number(value) : value,
    }));
  };

  const handleAdvantageToggle = (advantage: string) => {
    setFormData((prev) => ({
      ...prev,
      advantages: prev.advantages.includes(advantage)
        ? prev.advantages.filter(a => a !== advantage)
        : [...prev.advantages, advantage]
    }));
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return formData.job_title.trim() !== '';
      case 2:
        return formData.city.trim() !== '' && formData.postal_code !== 0;
      case 3:
        return formData.description.trim() !== '';
      case 4:
        return true; // Advantages are optional
      case 5:
        return true; // Review step is always valid
      default:
        return true;
    }
  };

  const renderStepIndicator = () => (
    <div className="flex items-center justify-center mb-8">
      {[1, 2, 3, 4, 5].map((index) => (
        <React.Fragment key={index}>
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              step === index
                ? 'bg-teal-500 text-white'
                : step > index
                ? 'bg-teal-900 text-teal-200'
                : 'bg-gray-700 text-gray-300'
            }`}
          >
            {index}
          </div>
          {index < 5 && (
            <div
              className={`w-16 h-0.5 ${
                step > index ? 'bg-teal-500' : 'bg-gray-600'
              }`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const renderReviewStep = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-white mb-4">Vérifiez les informations</h3>
      
      <div className="space-y-4">
        <div className="bg-gray-700 rounded-lg p-4">
          <h4 className="text-sm font-medium text-gray-300 mb-2">Informations principales</h4>
          <p className="text-white">{formData.job_title}</p>
          <p className="text-gray-400 text-sm mt-1">{formData.contract}</p>
        </div>

        <div className="bg-gray-700 rounded-lg p-4">
          <h4 className="text-sm font-medium text-gray-300 mb-2">Localisation</h4>
          <p className="text-white">{formData.city}</p>
          <p className="text-gray-400 text-sm mt-1">{formData.postal_code}</p>
        </div>

        {formData.salary > 0 && (
          <div className="bg-gray-700 rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-300 mb-2">Salaire annuel</h4>
            <p className="text-white">{formData.salary.toLocaleString('fr-FR')} €</p>
          </div>
        )}

        {formData.advantages.length > 0 && (
          <div className="bg-gray-700 rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-300 mb-2">Avantages ({formData.advantages.length})</h4>
            <div className="flex flex-wrap gap-2">
              {formData.advantages.map(advantage => (
                <span key={advantage} className="px-2 py-1 bg-gray-600 rounded-md text-sm text-white">
                  {advantage}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="bg-gray-700 rounded-lg p-4">
          <h4 className="text-sm font-medium text-gray-300 mb-2">Description</h4>
          <p className="text-white whitespace-pre-wrap">{formData.description}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-2xl mx-auto">
      {/* Business Info */}
      <div className="mb-8 p-4 bg-gray-800 rounded-lg">
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-lg bg-gray-700 text-teal-500">
            <Building2 className="h-6 w-6" />
          </div>
          <div>
            <h3 className="font-medium text-white">
              {business.company_name}
            </h3>
            <p className="text-sm text-gray-400">
              {business.area}
            </p>
          </div>
        </div>
      </div>

      {renderStepIndicator()}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {step === 1 && (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Titre du poste *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Briefcase className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="job_title"
                  value={formData.job_title}
                  onChange={handleChange}
                  className="block w-full pl-10 pr-3 py-2 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                  placeholder="ex: Paysagiste confirmé"
                  required
                />
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-1">
                  Ville *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MapPin className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                    placeholder="ex: Paris"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-200 mb-1">
                  Code postal *
                </label>
                <input
                  type="text"
                  name="postal_code"
                  value={formData.postal_code || ''}
                  onChange={handleChange}
                  className="block w-full py-2 px-3 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                  placeholder="ex: 75001"
                  pattern="[0-9]{5}"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Salaire annuel brut (€)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Euro className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  name="salary"
                  value={formData.salary || ''}
                  onChange={handleChange}
                  min="0"
                  step="1000"
                  className="block w-full pl-10 pr-3 py-2 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                  placeholder="ex: 35000"
                />
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Description du poste *
              </label>
              <div className="relative">
                <div className="absolute top-3 left-3">
                  <FileText className="h-5 w-5 text-gray-400" />
                </div>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={6}
                  className="block w-full pl-10 pr-3 py-2 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                  placeholder="Décrivez le poste, les missions et les compétences requises..."
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Type de contrat *
              </label>
              <select
                name="contract"
                value={formData.contract}
                onChange={handleChange}
                className="block w-full py-2 px-3 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                required
              >
                <option value="CDI">CDI</option>
                <option value="CDD">CDD</option>
                <option value="Interim">Intérim</option>
                <option value="Stage">Stage</option>
                <option value="Alternance">Alternance</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Expérience requise
              </label>
              <select
                name="required_experience"
                value={formData.required_experience}
                onChange={handleChange}
                className="block w-full py-2 px-3 border-2 border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
              >
                <option value="">Sélectionnez une expérience</option>
                <option value="Débutant">Débutant</option>
                <option value="1-2 ans">1-2 ans</option>
                <option value="3-5 ans">3-5 ans</option>
                <option value="5+ ans">5+ ans</option>
              </select>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="space-y-6">
            <div>
              <div className="flex items-center gap-2 mb-4">
                <Gift className="h-5 w-5 text-gray-400" />
                <label className="text-sm font-medium text-gray-200">
                  Avantages proposés
                </label>
              </div>
              <div className="grid grid-cols-2 gap-3">
                {JOB_ADVANTAGES.map((advantage) => (
                  <label
                    key={advantage}
                    className="flex items-center space-x-3 p-3 rounded-lg border-2 border-gray-600 bg-gray-700 cursor-pointer hover:border-teal-500 transition-colors"
                  >
                    <input
                      type="checkbox"
                      checked={formData.advantages.includes(advantage)}
                      onChange={() => handleAdvantageToggle(advantage)}
                      className="h-4 w-4 text-teal-500 rounded border-gray-500 focus:ring-teal-500 focus:ring-offset-gray-700"
                    />
                    <span className="text-sm text-white">{advantage}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        )}

        {step === 5 && renderReviewStep()}

        <div className="flex justify-between pt-6 border-t border-gray-600">
          <button
            type="button"
            onClick={() => step > 1 ? setStep(step - 1) : onCancel()}
            className="inline-flex items-center px-4 py-2 border-2 border-gray-500 rounded-lg text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-500"
          >
            <ChevronLeft className="h-5 w-5 mr-1" />
            {step === 1 ? 'Annuler' : 'Précédent'}
          </button>

          {step < 5 ? (
            <button
              type="button"
              onClick={() => setStep(step + 1)}
              disabled={!isStepValid()}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-teal-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Suivant
              <ChevronRight className="h-5 w-5 ml-1" />
            </button>
          ) : (
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-teal-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <>
                  <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-b-transparent mr-2"></div>
                  Création en cours...
                </>
              ) : (
                'Créer l\'offre'
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}