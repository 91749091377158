import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes'; // Ensure this file exports properly defined routes
import { Toast } from './components/Toast'; // Ensure this file exists and exports a functional Toast component
import { ErrorBoundary } from './components/ErrorBoundary';

/**
 * The main App component serves as the root component of the application.
 * It wraps the application in a Router for navigation and includes
 * a Toast component for global notifications.
 */
function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Toast />
        <AppRoutes />
      </ErrorBoundary>
    </Router>
  );
}

export default App;
