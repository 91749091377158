import { useState, useEffect, useCallback } from 'react';
import { Job } from '../types/job';
import { useResponsibleData } from './useResponsibleData';
import toast from 'react-hot-toast';

interface UseResponsibleJobsResult {
  jobs: Job[];
  loading: boolean;
  error: string | null;
  refreshJobs: () => Promise<void>;
  deleteJob: (id: number) => Promise<void>;
}

export function useResponsibleJobs(): UseResponsibleJobsResult {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { responsibleData } = useResponsibleData();

  const fetchJobs = useCallback(async () => {
    if (!responsibleData?.id) {
      console.warn('No responsible ID available');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await fetch('https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB/job');
      if (!response.ok) {
        throw new Error('Failed to fetch jobs');
      }

      const data = await response.json();
      
      // Filter jobs by responsible ID and sort by creation date
      const responsibleJobs = data
        .filter((job: Job) => Number(job.responsibles_id) === Number(responsibleData.id))
        .sort((a: Job, b: Job) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

      setJobs(responsibleJobs);
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Impossible de charger vos offres. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  }, [responsibleData?.id]);

  const deleteJob = async (id: number) => {
    try {
      const response = await fetch(`https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB/job/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete job');
      }

      toast.success('Offre supprimée avec succès');
      await fetchJobs(); // Refresh the job list
    } catch (error) {
      console.error('Error deleting job:', error);
      toast.error('Erreur lors de la suppression de l\'offre');
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  return {
    jobs,
    loading,
    error,
    refreshJobs: fetchJobs,
    deleteJob
  };
}