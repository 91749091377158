// Importing the Job type for type safety
import { Job } from '../types/job';

// Base URL for the API endpoints
const API_BASE_URL = 'https://x1lc-aku3-stjs.p7.xano.io/api:moW4brDB';

/**
 * Fetches a list of jobs from the API.
 * @returns A promise that resolves to an array of jobs.
 * @throws An error if the API request fails or returns a non-OK status.
 */
export async function fetchJobs(): Promise<Job[]> {
  // Send a GET request to the jobs endpoint
  const response = await fetch(`${API_BASE_URL}/job`);

  // Check if the response status is not OK (status code 200-299)
  if (!response.ok) {
    // Throw an error with a descriptive message
    throw new Error('Failed to fetch jobs');
  }

  // Parse and return the JSON response
  return response.json();
}
