import React, { useState } from 'react';
import { ChevronDown, X } from 'lucide-react';
import { Department, DepartmentFilterProps } from '../../../types/department';
import { FRENCH_DEPARTMENTS } from '../../../data/departments';
import { getDepartmentLabel } from '../../../utils/departmentUtils';

/**
 * A component for filtering items based on French departments.
 * Allows users to select and deselect departments, search within the list, and view selected items.
 */
export function DepartmentFilter({ 
  selectedDepartments, // Array of currently selected department codes
  onDepartmentChange // Callback for when a department is selected or deselected
}: DepartmentFilterProps) {
  const [isOpen, setIsOpen] = useState(false); // Controls whether the dropdown is open
  const [searchTerm, setSearchTerm] = useState(''); // Tracks the current search term for filtering departments

  // Filter the list of departments based on the search term
  const filteredDepartments = FRENCH_DEPARTMENTS.filter(dept => 
    getDepartmentLabel(dept.code, FRENCH_DEPARTMENTS)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Toggle a department's selection
  const toggleDepartment = (code: string) => {
    onDepartmentChange(code); // Call the parent-provided function to handle changes
  };

  return (
    <div className="relative">
      {/* Title */}
      <h3 className="text-lg font-semibold mb-3">Départements</h3>
      
      {/* Display tags for selected departments */}
      <div className="flex flex-wrap gap-2 mb-2">
        {selectedDepartments.map(code => (
          <span 
            key={code} 
            className="inline-flex items-center px-2 py-1 rounded-md bg-blue-100 text-blue-800 text-sm"
          >
            {getDepartmentLabel(code, FRENCH_DEPARTMENTS)} {/* Display the department's label */}
            <button
              onClick={() => toggleDepartment(code)} // Remove department from selection when clicked
              className="ml-1 hover:text-blue-600"
            >
              <X size={14} /> {/* Icon for removing the department */}
            </button>
          </span>
        ))}
      </div>

      {/* Dropdown toggle button */}
      <button
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown visibility
        className="w-full px-4 py-2 text-left border rounded-md bg-white flex items-center justify-between hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span className="text-gray-700">
          {selectedDepartments.length 
            ? `${selectedDepartments.length} département${selectedDepartments.length > 1 ? 's' : ''} sélectionné${selectedDepartments.length > 1 ? 's' : ''}`
            : 'Sélectionner des départements'} {/* Display count of selected departments */}
        </span>
        <ChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} size={20} /> {/* Arrow icon for dropdown */}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
          {/* Search bar inside dropdown */}
          <div className="p-2 border-b">
            <input
              type="text"
              value={searchTerm} // Bind input value to searchTerm
              onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm when user types
              placeholder="Rechercher un département..."
              className="w-full px-3 py-1 border rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* List of filtered departments */}
          <div className="max-h-60 overflow-y-auto">
            {filteredDepartments.map(dept => (
              <label
                key={dept.code} // Unique key for each department
                className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
              >
                <input
                  type="checkbox"
                  checked={selectedDepartments.includes(dept.code)} // Check if the department is selected
                  onChange={() => toggleDepartment(dept.code)} // Toggle department selection
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm">
                  {getDepartmentLabel(dept.code, FRENCH_DEPARTMENTS)} {/* Display department label */}
                </span>
              </label>
            ))}

            {/* Message if no departments match the search term */}
            {filteredDepartments.length === 0 && (
              <div className="px-4 py-2 text-sm text-gray-500">
                Aucun département trouvé
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
