import { Job, JobFilters } from '../types/job';
import { getDepartmentFromPostalCode } from './departmentUtils';

export function filterBySearch(job: Job, searchTerm: string): boolean {
  if (!searchTerm) return true;
  
  const searchLower = searchTerm.toLowerCase();
  const title = job.job_title?.toLowerCase() ?? '';
  const description = job.description?.toLowerCase() ?? '';
  const city = job.city?.toLowerCase() ?? '';
  const business = job.business?.toLowerCase() ?? '';
  
  return (
    title.includes(searchLower) ||
    description.includes(searchLower) ||
    city.includes(searchLower) ||
    business.includes(searchLower)
  );
}

export function filterByDepartment(job: Job, departments: string[]): boolean {
  if (!departments || departments.length === 0) return true;
  
  const jobDepartment = getDepartmentFromPostalCode(job.postal_code);
  return jobDepartment ? departments.includes(jobDepartment) : false;
}

export function filterByContracts(job: Job, contracts: string[]): boolean {
  if (!contracts || contracts.length === 0) return true;
  return job.contract && contracts.includes(job.contract);
}

export function filterJobs(
  jobs: Job[], 
  searchTerm: string, 
  filters: JobFilters
): Job[] {
  if (!Array.isArray(jobs)) return [];
  
  return jobs.filter(job => 
    filterBySearch(job, searchTerm) &&
    filterByDepartment(job, filters.departments) &&
    filterByContracts(job, filters.contracts)
  );
}