import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { cn } from '../../lib/utils';

interface StepCardProps {
  number: number;
  title: string;
  description: string;
  link: string;
  isExternal?: boolean;
}

export function StepCard({ number, title, description, link, isExternal }: StepCardProps) {
  const LinkComponent = isExternal ? 'a' : Link;
  const linkProps = isExternal ? { href: link, target: "_blank", rel: "noopener noreferrer" } : { to: link };

  return (
    <div className={cn(
      "group relative bg-white p-8 rounded-2xl",
      "border border-gray-100",
      "transition-all duration-300",
      "hover:shadow-xl",
      "overflow-hidden"
    )}>
      {/* Step Number */}
      <div className={cn(
        "absolute -top-6 -right-6",
        "w-24 h-24",
        "flex items-center justify-center",
        "text-4xl font-bold text-teal-50",
        "bg-teal-500/10",
        "rounded-full",
        "transition-transform duration-300",
        "group-hover:scale-110"
      )}>
        {number}
      </div>

      {/* Content */}
      <div className="relative">
        <h3 className="text-xl font-semibold text-gray-900 mb-3">
          {title}
        </h3>

        <p className="text-gray-600 leading-relaxed mb-6">
          {description}
        </p>

        <LinkComponent
          {...linkProps}
          className={cn(
            "inline-flex items-center",
            "text-teal-600 font-medium",
            "hover:text-teal-700",
            "transition-colors"
          )}
        >
          En savoir plus
          <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
        </LinkComponent>
      </div>
    </div>
  );
}