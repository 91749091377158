import React from 'react';
import { SlidersHorizontal } from 'lucide-react';

export function FilterHeader() {
  return (
    <div className="flex items-center gap-2 mb-4">
      <SlidersHorizontal className="text-yellow-600" />
      <h2 className="text-lg font-semibold text-gray-900">Filtrer les offres</h2>
    </div>
  );
}