// Import the Zustand `useAuth` store hook and the `User` type definition
import { useAuth as useZustandAuth } from '../store/auth';
import type { User } from '../types/auth';

/**
 * Custom hook to interact with the authentication store.
 * Provides authentication utilities such as checking the auth state, logging in, and logging out.
 */
export function useAuth() {
  // Destructure properties from the Zustand auth store
  const { token, user, login, logout } = useZustandAuth();

  // Determine if the user is authenticated
  const isAuthenticated = Boolean(token && user);

  /**
   * Logs in a user by storing the token and user information.
   * @param token - Authentication token.
   * @param user - User information.
   */
  const loginUser = (token: string, user: User) => {
    login(token, user); // Call the Zustand `login` function
  };

  // Return the authentication state and utility functions
  return {
    token,             // Current authentication token
    user,              // Current user information
    isAuthenticated,   // Boolean indicating if the user is authenticated
    login: loginUser,  // Function to log in a user
    logout,            // Function to log out a user
  } as const; // Ensures the returned object is read-only
}
