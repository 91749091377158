import { Link } from 'react-router-dom';
import { Mail, Phone, MapPin } from 'lucide-react';
import { getWhatsAppLink } from '../lib/utils';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary-dark text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Emploi paysagiste</h3>
            <p className="text-sm text-primary-lightest">
              Votre plateforme de recrutement spécialisée dans le secteur du paysagisme.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Liens rapides</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/jobs" className="text-sm hover:text-white transition-colors text-primary-lightest">
                  Offres d'emploi
                </Link>
              </li>
              <li>
                <Link to="/login" className="text-sm hover:text-white transition-colors text-primary-lightest">
                  Espace recruteur
                </Link>
              </li>
              <li>
                <a
                  href={getWhatsAppLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm hover:text-white transition-colors text-primary-lightest"
                >
                  Déposer un CV
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="flex items-center text-sm text-primary-lightest">
                <Phone className="h-4 w-4 mr-2" />
                <a
                  href="tel:+33745800515"
                  className="hover:text-white transition-colors"
                >
                  +33 7 45 80 05 15
                </a>
              </li>
              <li className="flex items-center text-sm text-primary-lightest">
                <Mail className="h-4 w-4 mr-2" />
                <a
                  href="mailto:contact@emploi-paysagiste.fr"
                  className="hover:text-white transition-colors"
                >
                  contact@emploi-paysagiste.fr
                </a>
              </li>
              <li className="flex items-center text-sm text-primary-lightest">
                <MapPin className="h-4 w-4 mr-2" />
                <span>Paris, France</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-primary-light text-sm text-center text-primary-lightest">
          <p>&copy; {currentYear} Emploi paysagiste. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
}